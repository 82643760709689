import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { Layout, TicketWidget, SeoComponent } from 'components';
import { LanguageContext } from 'context';

const EventTemplate = ({ data, pageContext }) => {
  const event = data.contentfulEvent;
  const headerItems = data.contentfulWebHeaderNavbar;
  const footerItems = data.contentfulWebFooterSection;
  const menuItems = data.allContentfulWebSite.nodes
    .flatMap((node) => node.pages.flatMap((p) => [p, p.subPages]))
    .flat()
    .filter(Boolean)
    .filter((item) => item.menuRelated);
  const topMenuItems = data.allContentfulWebSite.nodes
    .flatMap((node) => node.pages.flatMap((p) => [p, p.subPages]))
    .flat()
    .filter(Boolean)
    .filter((item) => item.topMenuRelated);
  const staticData = data.allContentfulDictionary.nodes;

  useEffect(() => {
    if (pageContext.nodeLocale === 'ar-SA') {
      document.documentElement.setAttribute('dir', 'rtl');
      document.documentElement.setAttribute('lang', 'ar');
      document.body.classList.add('rtl');
    } else {
      document.documentElement.removeAttribute('dir');
      document.documentElement.removeAttribute('lang');
      document.body.classList.remove('rtl');
    }
  }, [pageContext.nodeLocale]);

  return (
    <LanguageContext.Provider
      value={{
        currentLang: pageContext.nodeLocale,
        staticData,
      }}
    >
      <SeoComponent page={event} />
      <TicketWidget />
      <Layout
        pageContext={pageContext}
        menuItems={menuItems}
        headerItems={headerItems}
        footerItems={footerItems}
        topMenuItems={topMenuItems}
      >
        {/* <Event event={event} /> */}
        {/* {gallery && <CenteredGallery component={gallery} />}
        <EventSharePage pageContext={pageContext} />  */}
      </Layout>
    </LanguageContext.Provider>
  );
};

export const query = graphql`
  query GetEvent($nodeLocale: String, $seasonName: String, $eventSlug: String) {
    contentfulEvent(slug: { eq: $eventSlug }, node_locale: { eq: $nodeLocale }) {
      slug
      startDate
      node_locale
      id
      title: eventName
      endDate
      createdAt
      eventLocation
      eventDescription {
        eventDescription
      }
      eventMainImage {
        gatsbyImageData(placeholder: BLURRED, quality: 90, layout: FULL_WIDTH)
        description
      }
      
      season {
        seasonSlug
        raceName
      }
      category {
        title
        slug
      }
    }
    allContentfulWebSite(
      filter: { webSlug: { eq: $seasonName }, node_locale: { eq: $nodeLocale } }
      sort: { order: ASC, fields: createdAt }
    ) {
      nodes {
        title
        webSlug

        showAlert
        pages {
          node_locale
          slug
          title
          id
          menuRelated
          topMenuRelated
          gtAction
          gtEvent
          gtEventCategory
          gtLabel
          subPages {
            ... on ContentfulWebPage {
              id
              slug
              title
              gtAction
              gtEvent
              gtEventCategory
              gtLabel
            }
          }
        }
      }
    }
    allContentfulDictionary(filter: { node_locale: { eq: $nodeLocale } }) {
      nodes {
        contentItems {
          dictionary {
            title
          }
          keyString
          stringValue {
            stringValue
          }
        }
      }
    }
    contentfulWebMailChimpForm(node_locale: { eq: $nodeLocale }) {
      title
      type
      mainTitle
      description {
        description
      }
      successfulRegistration {
        successfulRegistration
      }
      postUrl
    }
    contentfulWebHeaderNavbar(node_locale: { eq: $nodeLocale }) {
      startDateCompetition
      days
      hours
      minutes
      seconds
      raceDate
      language
      newsItem
      logoImage {
        description
        file {
          url
        }
      }
      coloredLogoImage {
        description
        file {
          url
        }
      }
    }
    contentfulWebFooterSection(node_locale: { eq: $nodeLocale }) {
      description {
        raw
      }
      mainTitle
    }
  }
`;

export default EventTemplate;
